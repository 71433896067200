import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id: 'dashboards',
        title: 'Dashboard',
        type: 'item',
        icon: 'dashboard.svg',
        url: '/dashboard'
    },
    {
        id: 'user-manage',
        title: 'User Management',
        type: 'item',
        icon: 'set_user_icon.svg',
        url: '/user-manage'
    },
    {
        id: 'group-manage',
        title: 'Group Management',
        type: 'item',
        icon: 'set_group_icon.svg',
        url: '/group-manage'
    },
    {
        id: 'moderator-manage',
        title: 'Moderator List',
        type: 'item',
        icon: 'set_moderator_icon.svg',
        url: '/moderator-manage'
    },
    {
        id: 'moderator-requests',
        title: 'Moderator Requests',
        type: 'item',
        icon: 'set_moderator_request_icon.svg',
        url: '/moderator-requests'
    },
    // {
    //     id: 'reported users',
    //     title: 'Reported Users',
    //     type: 'item',
    //     icon: 'set_moderator_request_icon.svg',
    //     url: '/reported_users'
    // },
    {
        id: 'alerts',
        title: 'Alerts',
        type: 'item',
        icon: 'set_alerts_icon.svg',
        url: '/alerts'
    },

    {
        id: 'notifications',
        title: 'Notifications',
        type: 'item',
        icon: 'set_notification_icon.svg',
        url: '/notifications'
    },
    {
        id: 'Feedback List',
        title: 'Feedback List',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/feedback-list'
    },
    {
        id: 'Certificate',
        title: 'Certificate',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/certificate'
    },
    {
        id: 'plants added by user',
        title: 'Recently added plants',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/plants-added-by-user'
    },
    {
        id: 'Merchandise Store',
        title: 'Merchandise Store',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/merchandise-store'
    },
    {
        id: 'App Rewards',
        title: 'App Rewards',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/app-rewards'
    },
    {
        id: 'Events',
        title: 'Event management',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/events'
    },
    {
        id: 'Product Category',
        title: 'Product Category',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/product-category'
    },
    {
        id: 'Reward Activities',
        title: 'Reward Activities',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/reward-activities'
    },
    {
        id: 'Master Settings',
        title: 'Master Settings',
        type: 'item',
        icon: 'set_master_icon.svg',
        url: '/master-settings'
    },
    {
        id: 'Settings',
        title: 'Settings',
        type: 'item',
        icon: 'set_setting_icon.svg',
        url: '/settings'
    },
    {
        id: 'Pages',
        title: 'Pages',
        type: 'item',
        icon: 'set_page_icon.svg',
        url: '/pages'
    },
    

    // ------------------------------------------------------------
    // {
    //     id: 'distributors',
    //     title: 'Distributors',
    //     type: 'item',
    //     icon: 'wc',
    //     url: '/distributors'
    // },
    // {
    //     id: 'events',
    //     title: 'Events',
    //     type: 'item',
    //     icon: 'event',
    //     url: '/events'
    // },
    // {
    //     id: 'tickets',
    //     title: 'Tickets',
    //     type: 'item',
    //     icon: 'local_activity',
    //     url: '/tickets'
    // },
    // {
    //     id: 'smspack',
    //     title: 'SMS Pack',
    //     type: 'item',
    //     icon: 'textsms',
    //     url: '/smspack'
    // },
    // {
    //     id: 'verifiers',
    //     title: 'Verifiers',
    //     type: 'item',
    //     icon: 'wc',
    //     url: '/verifiers'
    // },
    // {
    //     id: 'notification',
    //     title: 'Notification',
    //     type: 'item',
    //     icon: 'notifications',
    //     url: '/notification'
    // },
    // {
    //     id: 'customers',
    //     title: 'Customers',
    //     type: 'item',
    //     icon: 'groups',
    //     url: '/customers'
    // },
    // {
    //     id: 'settings',
    //     title: 'Settings',
    //     type: 'item',
    //     icon: 'settings',
    //     url: '/settings'
    // },
    // {
    //     id: 'venue',
    //     title: 'Manage Venues',
    //     type: 'item',
    //     icon: 'location_city',
    //     url: '/masters/venue'
    // },
    // {
    //     id: 'eventTypes',
    //     title: 'Manage Event Types',
    //     type: 'item',
    //     icon: 'event_note',
    //     url: '/masters/event-type'
    // },
    // {
    //     id: 'eventCategory',
    //     title: 'Manage Event Categories',
    //     type: 'item',
    //     icon: 'event_note',
    //     url: '/masters/event-category'
    // },
    // {
    //     id: 'ticketTypes',
    //     title: 'Manage Ticket Types',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/masters/ticket-type'
    // },
    // {
    //     id: 'organiser',
    //     title: 'Manage Organisers',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/masters/organiser'
    // },
    // {
    //     id: 'guest',
    //     title: 'Manage Guests',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/masters/guest'
    // },
    // {
    //     id: 'roles',
    //     title: 'Roles',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/roles'
    // },
    // {
    //     id: 'subuser',
    //     title: 'Sub User',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/subuser'
    // },
    // {
    //     id: 'printTicket',
    //     title: 'Print Tickets',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/print-tickets'
    // },
    // {
    //     id: 'order',
    //     title: 'Orders',
    //     type: 'item',
    //     icon: 'confirmation_number',
    //     url: '/orders'
    // },
    
    
    
    
    // {
    //     id: 'settings',
    //     title: 'Change Password',
    //     type: 'item',
    //     icon: 'lock',
    //     url: '/settings/change-password'
    // },
    // {
    //     id: 'logout',
    //     title: 'Logout',
    //     type: 'item',
    //     icon: 'exit_to_app',
    //     url: '/auth/login'
    // },
]
