import { HttpHeaders } from '@angular/common/http';

export const ApiEndPointUrl = 'https://test.plantcare.in/api/v3/';

// export const ApiEndPointUrl = 'https://api.plantcare.in/api/v2/' ;

// export const DefaultFilter = {
//   "pageIndex": 1,
//   "pageSize": 10,
//   "search": "",
//   "startDate": 0,
//   "endDate": 0,
//   "eventStatus": 1
// }

export class MyHeaders {
  static getMyHeaders() {
    return {
      reqHeadersJSON: {
        headers: new HttpHeaders({
          'Authorization': localStorage.getItem('userToken') === null || localStorage.getItem('userToken') === undefined ? '' : localStorage.getItem('userToken'),
          'Content-Type': 'application/json'
        })
      },
      reqHeadersFormData: {
        headers: new HttpHeaders({
          'Authorization': localStorage.getItem('userToken')
        })
      }
    }
  }
}




export const pageSize = 10

// export const ReqHeader = {
//   reqHeadersJSON: {
//     headers: new HttpHeaders({
//       'Authorization': localStorage.getItem('userToken'),
//       'Content-Type': 'application/json'
//     })
//   },
//   reqHeadersFormData: {
//     headers: new HttpHeaders({
//       'Authorization': localStorage.getItem('userToken')
//     })
//   }
// }

// pattern validation
export enum Pattern {
  Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  Mobile = '[0-9]+',
  MobileMinLength = 10,
  MobileMaxLength = 12,
  Password = '^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$',

}

export const Enums = {
  GENDER: {
    1: 'Male',
    2: 'Female'
  },
  certificate_type: {
    'watering': 'Watering',
    'plantation': 'Plantation',
    'events': 'Events',
    'manual':'Manual',
    'welcome':'Welcome'
  },
platform_type:{
  1:'Android',
  2:'ios'
},
  FILTER: {
    '':"All",
    'd': "Daily",
    'w': "Week",
    'm': "Month",
    'y': "Year"
  },

  EVENT_STATUS: {
    1: "Upcoming",
    2: "Postpon",
    3: "Cancel",
    4: "Live",
    5: "Expired"
  },

  rewardtype: {
    '': "All",
    'referFriend': "Refer a friend",
    'addPlant': "Plant reward",
    'waterPlant': "Water reward",
    'holdEvent': "Event"
  }

}