import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-user-dailog',
  templateUrl: './user-dailog.component.html',
  styleUrls: ['./user-dailog.component.scss']
})
export class UserDailogComponent implements OnInit {
  dialogRef: any;
  confirmButtonText:string = 'Yes';
  cancelButtonText:string = 'Cancel';
  data: any;
  form = this.fb.group({
    restrictType:['1'],
    numberOfDays:[''],
    status:[1],
    id:['']
  })
  errMsg: boolean;
  enableMode: boolean;
  constructor(public dialog: MatDialog,
    public matDailogRef: MatDialogRef<UserDailogComponent>,
    private fb:FormBuilder,
    @Inject(MAT_DIALOG_DATA) public options) {
    // this.data = options.dailogData
    console.log("dialog's comming value",options.dailogData);
     if(!options.dailogData.status){
       this.enableMode = true;
     }
      this.form.get('id').setValue(options.dailogData._id);
      
  }
  ngOnInit() {
   
  }
  closeDialog() {
    this.matDailogRef.close();
  }
  confirmDailog() {
    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '120px',
      width: '420px',
    });
  }
  submit(){
    if(this.form.valid)
      this.matDailogRef.close({restrict:true,form:this.form.value})
    else
      this.errMsg = true;
  }
  
  enable(){
    const payload = {
      id:this.options.dailogData._id,
      status: false
    }
    this.matDailogRef.close({enable:true,payload})
  }
  
  restrictTypeChange(event){
    console.log(event.value);
   if(event.value =='2')
      this.form.get('numberOfDays').setValue('');
  }
}