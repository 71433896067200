import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserManageService } from 'app/main/user-manage/user-manage/user-manage.service';
import { UserDailogComponent } from '../user-dailog/user-dailog.component';

@Component({
  selector: 'app-single-user-dailog',
  templateUrl: './single-user-dailog.component.html',
  styleUrls: ['./single-user-dailog.component.scss']
})
export class SingleUserDailogComponent implements OnInit {
  singleUser:any; 
  date: any
  displayDate: any
  constructor(public dialog: MatDialog,private userManageService:UserManageService,
    public matDialogRef: MatDialogRef<SingleUserDailogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
      // console.log("id in dailog",data.dailogData);
      
      // this.userManageService.singleUser(data.dailogData).then((result:any)=>{
      //   console.log(result.data)
        this.singleUser = data.dailogData;
      // })
  }

  ngOnInit() {
    console.log("this is data on openeing dialog", this.data.dailogData)
    console.log("this is single user", this.singleUser)
    this.date = new Date(this.singleUser.createdAt).toDateString()
    this.displayDate = this.date.slice(4, 15)
  }
  userDailog(id){
    this.matDialogRef.close()
    let dialogRef = this.dialog.open(UserDailogComponent, {
      minHeight: '120px',
      width: '420px',
      data: {
        'dailogData': this.singleUser
      }
    })
  }
  closeDialog() {
    this.matDialogRef.close()
  }
}
