import { Injectable } from '@angular/core';
import { CommonService } from '@fuse/services/common.service';
import { HttpClient } from '@angular/common/http';
import { ApiEndPointUrl ,MyHeaders,pageSize} from '@fuse/utils/systemEnums';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UserManageService implements Resolve<any>{
  users:any;
  tableData: any;
  constructor(public commonService: CommonService,
    private _httpClient: HttpClient) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getUsers()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }
  getUsers(pageNumber?,date?,keyword?,columnName?,orderby?) {
    console.log("orderby",orderby);
    
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/users',{
        keyword:keyword?keyword:'',
        date:date?date:'',
        pageIndex:pageNumber?pageNumber:1,
        columnName:columnName?columnName:'',
        orderby:orderby,
        pageSize:pageSize
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status == 200) {
            console.log(result.data.users);          
            this.tableData = result.data;
          }else{
            this.users = [];
          }
          resolve(result)

        }, reject)
    })
  }

  deleteUsers(id) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/users/delete',{id: id}, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {            
            this.users = result.data
            console.log("delete user",this.users);
            
            resolve(result)
          }else{
            this.users = []
          }
        }, reject)
    })
  }
  singleUser(id) {
    return new Promise((resolve, reject) => {
      this._httpClient.get(ApiEndPointUrl + 'admin/users/'+id, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            this.users = result.data
            resolve(result)
          }
        }, reject)
    })
  }
  
  restrictUser(payload) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/users-status',payload, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            resolve(result)
          }
        }, reject)
    })
  }
  addCertificateToUser(payload){
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/addCertificateToUser',payload, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status) {
            resolve(result)
          }
        }, reject)
    })
  }
}
