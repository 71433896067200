import { Injectable } from '@angular/core';
import { ApiEndPointUrl, MyHeaders } from '@fuse/utils/systemEnums';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(
    private _httpClient: HttpClient,
    private location: Location
  ) { }
  
  getMaster() {
    return new Promise((resolve, reject) => {
      this._httpClient.get(ApiEndPointUrl + 'admin/profile', MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          resolve(result)
        }, reject)
    })
  } 

  updatePointsAndModeratorRange(points?,moderatorRange?) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'admin/updateAdminData',
      {points:points?points:'',moderatorRange:moderatorRange? moderatorRange:''},
           MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          resolve(result)
        }, reject)
    })
  } 

  // getStates(countryId) {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.post(ApiEndPointUrl + 'admin/getall/state', { countryId: countryId }, ReqHeader.reqHeadersJSON)
  //       .subscribe((result: any) => {
  //         if (result.status == 200) {
  //           resolve(result);
  //         } else
  //           reject(result)
  //       }, reject)
  //   })
  // }



  // getAllCurrencies() {
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.get(ApiEndPointUrl + 'admin/getall/currencies', ReqHeader.reqHeadersJSON)
  //       .subscribe((result: any) => {
  //         if (result.status == 200) {
  //           resolve(result.data.currencies)
  //         }
  //       }, reject)
  //   })
  // }

  modifyUser(userId?,upgradeStatus?) {
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/upgradeUserToModerator',{
        userId:userId,
        upgradeStatus:upgradeStatus
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          resolve(result)
        }, reject)
    })
  }


  
  getAllCertifcates(keyword?) {
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/certificateList',{
        keyword : keyword 
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          resolve(result)
        }, reject)
    })
  }

  goBack() {
    this.location.back();
  }


  uploadImage(imageFile) {
    const formData = new FormData();
    formData.append("image", imageFile)

    return new Promise((resolve, reject) => {
      this._httpClient.post(ApiEndPointUrl + 'account/uploadImage', formData, MyHeaders.getMyHeaders().reqHeadersFormData)
        .subscribe((result: any) => {
          if (result.status == 200) {
            resolve(result)
          }
        }, reject)
    })
  }

  // getCurrencySymbol() {
  //   return localStorage.getItem("symbol")
  // }

  getHeader(headerType?:'formData') {
    if (headerType === 'formData') {
      return {
        headers: new HttpHeaders({
          'Authorization': localStorage.getItem('userToken')
        })
      }
    }else
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('userToken'),
        'Content-Type': 'application/json'
      })
    }

  }
}
