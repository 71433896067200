import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-moderator-request-dailog',
  templateUrl: './moderator-request-dailog.component.html',
  styleUrls: ['./moderator-request-dailog.component.scss']
})
export class ModeratorRequestDailogComponent implements OnInit {

  constructor(public dialog: MatDialog,
              public matDialogRef: MatDialogRef<ModeratorRequestDailogComponent>,
              @Inject(MAT_DIALOG_DATA) public data) { 
                console.log("hello there",data.element);
                
              }

  ngOnInit() {
  }
  closeDialog(){
    this.matDialogRef.close();
  }
  // confirmDailog(){
  //   let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
  //     height: '120px',
  //     width: '420px',
  //   });
  // }
  close(){  
    this.matDialogRef.close({accept:true});
  }
}
