import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { validateBasis } from '@angular/flex-layout';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-update-banner-and-title',
  templateUrl: './update-banner-and-title.component.html',
  styleUrls: ['./update-banner-and-title.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class UpdateBannerAndTitleComponent implements OnInit {
  userProfilePic: any;
  imagePath: any;
  imgURL: string | ArrayBuffer;
  form: FormGroup;

  constructor(
    public matDialogRef: MatDialogRef<UpdateBannerAndTitleComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public _snackBar: MatSnackBar) { 
      console.log("this is image",data.image);

      if (data) {
        console.log("this is element",data.element);
        
        if(data.image)
        this.imgURL = data.image;
      }

      this.form  = new FormGroup({
        id: new FormControl(this.data._id , [ Validators.required ]),
        title : new FormControl(this.data.title , [ Validators.required ]),
        image: new FormControl('')
      })
  
    }

    ngOnInit() {

    }

  closeDialog() {
      this.matDialogRef.close({submit:false})
  }

  removeImage() {
    this.imgURL = undefined;
    this.form.get('image').setValue('');
  }


  takeProfileImg(event) {
    console.log("console after upload image",event.target.files[0]);
    this.userProfilePic = event.target.files[0]

    var reader = new FileReader();
    this.imagePath = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    this.form.get('image').setValue(event.target.files[0]);
    // console.log("this is the value which will get the image input",this.form)
    reader.onload = (_event) => {
      this.imgURL = reader.result;

    }
  } 


  submit() {
    this.matDialogRef.close({submit:true, ...this.form.value});
  }

}
