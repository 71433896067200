import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MasterSettingsService } from 'app/main/master-settings/master-settings/master-settings.service';

@Component({
  selector: 'app-add-report-dialog',
  templateUrl: './add-report-dialog.component.html',
  styleUrls: ['./add-report-dialog.component.scss']
})
export class AddReportDialogComponent implements OnInit {
  reportCompo: { };
  errMsg: boolean = false;

  constructor(public matDialogRef: MatDialogRef<AddReportDialogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public _snackBar: MatSnackBar
    , public masterSettingsService: MasterSettingsService,
    public progressBar: FuseProgressBarService,) {
      console.log(data);
      
     }

  ngOnInit() {
  }

  submit(val) {
    console.log(val);
    if(val){
      if(this.data.mode == 'add'){  this.reportCompo = {'name':val}}
      else{
        this.reportCompo = {
          'name':val,
          'id':this.data.element._id
        }
      }
      
     this.matDialogRef.close(this.reportCompo);
    }else{
      this.errMsg = true;
    }
    
  }
  closeDialog() {
    this.matDialogRef.close()
  }
}
