import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-add-certificate-dailog',
  templateUrl: './add-certificate-dailog.component.html',
  styleUrls: ['./add-certificate-dailog.component.scss']
})
export class AddCertificateDailogComponent implements OnInit {
  form  : FormGroup;
  allCertificates: any;
  constructor(
    private fb : FormBuilder,
    private commonService : CommonService,
    private snackBar : MatSnackBar,
    public matDialogRef: MatDialogRef<AddCertificateDailogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    ) {
console.log("this._data._id ==>",this._data.dailogData._id);

      this.commonService.getAllCertifcates().then((result:any)=>{
        if(result.status === 200){
          this.allCertificates = result.data;
          console.log('this.allCertificates ===>',result.data);
          
        }else{
          this.snackBar.open('Something went wrong', 'Okay' , { duration : 2000 })
        }
      })
   }

  ngOnInit() {
    this.form = this.fb.group({
      certificateId : ['',Validators.required],
      userId : [this._data.dailogData._id ? this._data.dailogData._id : ''],
      date : ['',Validators.required] 
    })
  }


  onSubmit(){
    
    console.log(this.form.value);
    if(this.form.valid){
      const payload : any = this.form.value;
      payload.date  = payload.date.format('x');
      this.matDialogRef.close(payload);
    }else{
      this.form.markAllAsTouched();
    }
  }
}
