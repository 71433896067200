import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiEndPointUrl, MyHeaders, pageSize } from '@fuse/utils/systemEnums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GroupManageService implements Resolve<any>{
  tableData: any;
  groupMemberData: any;

  constructor(private _httpClient:HttpClient) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {

      Promise.all([
        this.getGroup()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }


  getGroup(pageNumber?,keyword?,date?,columnName?,orderby?) {
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/groupList',{
        keyword:keyword?keyword:'',
        date:date?date:'',
        pageIndex:pageNumber?pageNumber:1,
        pageSize:pageSize,
        columnName:columnName?columnName:'',
        orderby:orderby,
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status == 200) {
            console.log(result.data);          
            this.tableData = result.data;
          }else{
            // this.users = [];
          }
          resolve(result)

        }, reject)
    })
  }

  
  groupMemberList(groupId?,pageNumber?,columnName?,orderby?){
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/groupMemberList',{
        groupId:groupId,
        pageIndex:pageNumber?pageNumber:1,
        pageSize:pageSize,
        columnName:columnName?columnName:'',
        orderby:orderby,
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status == 200) {
            console.log(result.data);          
            this.groupMemberData = result.data;
          }else{
            // this.users = [];
          }
          resolve(result)

        }, reject)
    })
  }


  groupDeleteUser(groupId,id){
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/groupDeleteUser',{
        groupId:groupId,
        userId:id
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          resolve(result)

        }, reject)
    })
  }
  deleteGroup(id) {
    return new Promise((resolve, reject) => {
      
      this._httpClient.post(ApiEndPointUrl+'admin/group/delete',{
        groupId:id
      }, MyHeaders.getMyHeaders().reqHeadersJSON)
        .subscribe((result: any) => {
          if (result.status == 200) {
            // console.log(result.data);          
            // this.tableData = result.data;
          }else{
            // this.users = [];
          }
          resolve(result)

        }, reject)
    })
  }

}
