import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { pageSize } from '@fuse/utils/systemEnums';
import { GroupManageService } from 'app/main/group-manage/group-manage/group-manage.service';
import { UserManageService } from 'app/main/user-manage/user-manage/user-manage.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { UserDailogComponent } from '../user-dailog/user-dailog.component';

@Component({
  selector: 'app-users-list-dailog',
  templateUrl: './users-list-dailog.component.html',
  styleUrls: ['./users-list-dailog.component.scss']
})
export class UsersListDailogComponent implements OnInit {
  config: any = {
    itemsPerPage: pageSize,
    currentPage:1,
    totalItems:0,
    id:'first'
  };
  displayedColumns: string[] = ['sr', 'images', 'name', 'joinDate', 'mobile', 'city', 'action'];
  dataSource: any = undefined;
  groupId: any;
  orderBy: number;
  columnName: any;
  constructor(public dialog: MatDialog,
    private groupManageSerivce: GroupManageService,
    private userManageService: UserManageService,
    private snackBar:MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.groupId = data.id;
    this.fetchDataInTable(this.groupId)
    console.log('this.config',this.config);
    
  }

  ngOnInit() {
  }

  pageChanged(pageNumber) {
    this.fetchDataInTable(this.groupId, pageNumber)
  }

  sortTable(col) {
    this.columnName = col;

    let ot = this.orderBy == 1 ? -1 : 1
    this.orderBy = ot
    console.log("this.orderBy", this.orderBy);

    this.fetchDataInTable(this.groupId, this.config.currentPage, col, this.orderBy)
  }
  
  deleteUser(id) {
    console.log("hello there");

    let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '120px',
      width: '420px',
    }).afterClosed().subscribe((result: any) => {
      if (result.delete) {
        this.groupManageSerivce.groupDeleteUser(this.groupId,id).then((result: any) => {
          if (result.status == 200) {
            const lastDigit = this.config.totalItems % 10;
            this.fetchDataInTable(this.groupId, lastDigit === 1 ? this.config.currentPage - 1 : this.config.currentPage)
          }
          this.snackBar.open(result.message,"Okay",{duration:2000})
        })
      }
    });
  }
  openUserDailog(e) {
    let dialogRef = this.dialog.open(UserDailogComponent, {
      minHeight: '120px',
      width: '420px',
      data:{
        dailogData:e
      }
    });
  }

  fetchDataInTable(groupId, pageNumber?, columnName?, orderBy?) {
    this.groupManageSerivce.groupMemberList(groupId, pageNumber, columnName, orderBy).then((result: any) => {
      if (result.status == 200) {
        console.log(result);
        this.dataSource = result.data.groupMemberList;
        this.config.currentPage = result.data.pageIndex;
        this.config.totalItems = result.data.totalRecords;
        console.log('this.config',this.config);
        
      }
    })
  }
}
