import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { ShowHideDirective } from '@angular/flex-layout';
import { MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialogRef } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MasterSettingsService } from 'app/main/master-settings/master-settings/master-settings.service';
import { DomSanitizer } from "@angular/platform-browser";
@Component({
  selector: 'app-add-plant-dailog',
  templateUrl: './add-plant-dailog.component.html',
  styleUrls: ['./add-plant-dailog.component.scss']
})
export class AddPlantDailogComponent implements OnInit {
  // @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef
  userProfilePic: any = '';
  imagePath: any;
  imgURL: string | ArrayBuffer;
  constructor(public matDialogRef: MatDialogRef<AddPlantDailogComponent>
    , @Inject(MAT_DIALOG_DATA) public data: any
    , public _snackBar: MatSnackBar
    , public masterSettingsService: MasterSettingsService,
    public progressBar: FuseProgressBarService,) {
    // console.log("image",data.element.image);

    if (data.element) {
      if(data.element.image)
      this.imgURL = data.element.image;
    }

  }

  ngOnInit() {
    console.log(this.imgURL);

  }
  closeDialog() {
    this.matDialogRef.close({submit:false})
  }
  takeProfileImg(event) {
    console.log(event.target.files[0]);
    this.userProfilePic = event.target.files[0]

    var reader = new FileReader();
    this.imagePath = event.target.files[0];
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
      // console.log(this.imgURL);

    }
  }


  submit(input) {
    console.log("inpupt",input);
    console.log("imgPAth",this.imgURL);
    console.log(this.data.element);
    
    
    if(!input)
      this._snackBar.open("Form is invalid",'Okay',{duration:2000})
    else if(this.data.element!= undefined)
      this.editPlant(input)
    else 
      this.addPlant(input)
  }

  addPlant(input) {
    console.log("in the add plant fn");
    let formData = new FormData()
    formData.append('name', input);
    formData.append('image', this.imagePath)
    this.progressBar.show();

    this.masterSettingsService.addPlant(formData).then((result: any) => {
      this._snackBar.open(result.message,"Okay",{duration:2000})
      if (result.status == 200) {        
        this.matDialogRef.close({submit:true})
      } else
        this.matDialogRef.close({submit:false});
      this.progressBar.hide();
    })

  }

  editPlant(input) {
    console.log("in the edit plant fn");
    
    this.progressBar.show();
    const formData = new FormData()
    formData.append('id', this.data.element._id)
    formData.append('image', this.imagePath)
    formData.append('name', input)
   
    this.masterSettingsService.editPlant(formData).then((result: any) => {
      this._snackBar.open(result.message, "okay", { duration: 1000 })
      if (result.status) {
        this.matDialogRef.close({submit:true})
      } else
        this.matDialogRef.close({submit:false});
      this.progressBar.hide();
    })

  }
  
  removeImage() {
    this.imgURL = undefined;

  }
  // addReport(input) {
  //   this.progressBar.show();
  //   this.masterSettingsService.addReport(input).then((result: any) => {
  //     this._snackBar.open(result.message, "okay", { "duration": 1000 })
  //     if (result.status) {
  //       this.matDialogRef.close(input)
  //     } else
  //       this.matDialogRef.close();
  //     this.progressBar.hide();
  //   })

  // }
  // editReport(input) {
  //   this.progressBar.show();
  //   const id = this.data.element.id
  //   this.masterSettingsService.editReport(input, id).then((result: any) => {
  //     this._snackBar.open(result.message, "okay", { "duration": 1000 })
  //     if (result.status) {
  //       this.matDialogRef.close(input)
  //     } else
  //       this.matDialogRef.close();
  //     this.progressBar.hide();
  //   })
  // }


}


