import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthGuardService } from './auth-guard.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmCoreModule } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { AppHttpInterceptor } from '@fuse/services/interceptor.service';




const appRoutes: Routes = [
    {
        path: 'dashboard',
        loadChildren: './main/dashboard/project/project.module#ProjectDashboardModule',
        canActivate: [AuthGuardService]
        // canLoad: [AuthGuardService],
    },
    {
        path: 'auth',
        loadChildren: './main/pages/pages.module#PagesModule',
    },    
    { 
        path: 'preview',
        loadChildren: './main/preview/preview.module#PreviewModule'
    },
    { 
        path: 'privacy-policy',
        loadChildren: './main/terms-conditions/terms-conditions.module#TermsConditionsModule'
    },
    {
        path: 'user-manage',
        loadChildren: './main/user-manage/user-manage.module#UserManageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'group-manage',
        loadChildren: './main/group-manage/group-manage.module#GroupManageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'moderator-manage',
        loadChildren: './main/moderator-manage/moderator-manage.module#ModeratorManageModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'moderator-requests',
        loadChildren: './main/moderator-request/moderator-request.module#ModeratorRequestModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'alerts',
        loadChildren: './main/alerts/alerts.module#AlertsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'notifications',
        loadChildren: './main/notifications/notifications.module#NotificationsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'master-settings',
        loadChildren: './main/master-settings/master-settings.module#MasterSettingsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'reported_users',
        loadChildren: './main/reported-users/reported-users.module#ReportedUsersModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'certificate',
        loadChildren: './main/certificate/certificate.module#CertificateModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'plants-added-by-user',
        loadChildren: './main/plants-added-by-user/plants-added-by-user.module#PlantsAddedByUserModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'merchandise-store',
        loadChildren: './main/merchandise-store/merchandise-store.module#MerchandiseStoreModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'app-rewards',
        loadChildren: './main/app-rewards/app-rewards.module#AppRewardsModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'product-category',
        loadChildren: './main/product-category/product-category.module#ProductCategoryModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'reward-activities',
        loadChildren: './main/reward-activities/reward-activities.module#RewardActivitiesModule',
        // canActivate: [AuthGuardService]
    },
    {
        path: 'feedback-list',
        loadChildren: './main/feedback/feedback.module#FeedbackModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'pages',
        loadChildren: './main/user-pages/user-pages.module#UserPagesModule',
        canActivate: [AuthGuardService]
    },
    {
        path: 'settings',
        loadChildren: './main/settings/settings.module#SettingsModule',
        canActivate: [AuthGuardService]
    },
    { path: 'events', loadChildren: () => import('./main/events/events.module').then(m => m.EventsModule) },
    // Main Routes
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    { path: 'login', redirectTo: 'auth/login', pathMatch: 'full' },
    { path: 'forgot-password', redirectTo: 'auth/forgot-password', pathMatch: 'full' },
    // {path: 'lock',redirectTo: 'auth/lock',pathMatch: 'full'},
    // { path: 'reset-password', redirectTo: 'auth/reset-password', pathMatch: 'full' },
    {
        path: '**',
        redirectTo: 'dashboard'
    },
];

@NgModule({
    declarations: [
        AppComponent,
       
    ],
    imports: [
        GooglePlaceModule,
        BrowserModule,
        NgxMaterialTimepickerModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDosbgI6M3Iw9abM1cQTvFpOeJI3qpfIEs',
            libraries: ['places']
        }),
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true
        },
    ]
})

export class AppModule {
}
